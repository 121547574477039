import jsonApi from './json-api';

const API_SECTION = 'cert';

interface CertClassRequest {
  cert_body: string;
}

interface CertClassResponse {
  cert_class: string;
}

export interface MockCert {
  pure_pem: string;
  thumbprint: string;
  attributes: {
    common_name: string;
    country: string;
    given_name: string;
    inn_legal_entity: string;
    locality: string;
    ogrn: string;
    organization: string;
    position: string;
    province: string;
    serial_number: string;
    snils: string;
    street_address: string;
    surname: string;
  };
}

interface CertMockRequest {
  cert_class: string;
}

function certClass(cert_body: string): Promise<CertClassResponse> {
  if (!cert_body?.length) {
    return Promise.resolve({ cert_class: '' });
  }
  return jsonApi.post<CertClassRequest, CertClassResponse>(`${API_SECTION}/class`, {
    cert_body,
  });
}

function certMockClass(): Promise<any> {
  return jsonApi
    .post<CertMockRequest, MockCert>(`cert/mock`, {
      cert_class: 'legal_entity',
    })
    .catch((error) => {
      console.error('Error fetching the certificate:', error);
      return null;
    });
}

function certMockBulk(): Promise<any> {
  return jsonApi.get(`cert/mock/bundle`).catch((error) => {
    console.error('Error fetching the certificate bundle:', error);
    return null;
  });
}

export { certClass, certMockClass, certMockBulk };
