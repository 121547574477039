import { useTranslation } from 'react-i18next';

import { Loader } from 'components/loader/loader';
import { useMeQuery } from 'store/api/profile';
import s from './HeaderProfile.module.scss';

export const HeaderProfile = () => {
  const { data: userInfo, isFetching } = useMeQuery();
  const { t } = useTranslation();

  if (isFetching) {
    return <Loader size="big" />;
  }

  return (
    <div className={s['continer']}>
      <div className={s['wrapper-name']}>
        <span>{userInfo?.firstName}</span>
        <span>{userInfo?.lastName}</span>
      </div>

      <div className={s['wrapper-attributes']}>
        {/* {userInfo?.id && (
          <div className={s['attribute-item']}>
            <span>ID:</span>
            <span>{userInfo?.id ?? ''}</span>
          </div>
        )} */}
      </div>
    </div>
  );
};
