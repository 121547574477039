import { useTranslation } from 'react-i18next';

import { useMeQuery } from 'store/api/profile';
import s from './HeaderProfile.module.scss';
import { Loader } from 'components/loader/loader';
import { useParams } from 'react-router-dom';
import { useGetMyOrganizationByIdQuery } from 'store/api/organizations';

const attributesWhitelist = ['snils', 'inn'];

export const HeaderProfile = () => {
  const { id } = useParams();

  const { data: [userInfo] = [], isFetching } = useGetMyOrganizationByIdQuery(
    { id: id ?? '' },
    { skip: !id },
  );

  const { t } = useTranslation();

  if (isFetching) {
    return <Loader size="big" />;
  }

  return (
    <div className={s['continer']}>
      <div className={s['wrapper-name']}>
        <span>{userInfo?.name}</span>
      </div>

      <div className={s['wrapper-attributes']}>
        {userInfo?.attributes
          ?.filter((item) => attributesWhitelist?.includes(item?.name))
          ?.filter((item) => item.value)
          ?.map((item) => (
            <div className={s['attribute-item']} key={item?.name}>
              <span>{t(item.name)}:</span>
              <span>{item.value}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
