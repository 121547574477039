// LayoutAuth.tsx
import React from 'react';
import { Header } from './header';
import { Footer } from './footer';
import styles from './LayoutAuth.module.scss'; // Import the CSS module

interface LayoutMainProps {
  children: React.ReactNode;
}

function LayoutAuth({ children }: LayoutMainProps) {
  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.content}>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default LayoutAuth;
