import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { controlError } from 'utils/controlError';
import { InputProps } from '../input-field/types';

import { CustomTooltip } from '../tooltip/custom-tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/information.svg';
import { ReactComponent as RequiredIcon } from 'assets/images/required.svg';

import { ErrorLabel } from 'components/error-label';
import { useMemo } from 'react';
import s from './date-picker.module.scss';

dayjs.extend(customParseFormat);

export enum DateFormatList {
  DD_MM_YYYY = 'DD.MM.YYYY',
  DD_MM_YY = 'DD.MM.YY',
  DD_MM_YYYY_DASH = 'DD-MM-YYYY',
  DD_MM_YY_DASH = 'DD-MM-YY',
  MM_YYYY = 'MM.YYYY',
}

const defaultDateFormat = DateFormatList.DD_MM_YYYY;

const dateFormatList = ['DD.MM.YYYY', 'DD.MM.YY', 'DD-MM-YYYY', 'DD-MM-YY', 'MM.YYYY'];

export const DatePickerMode = {
  SINGLE: 'single',
  RANGE: 'range',
};

export const DatePickerField = ({
  info,
  name,
  label,
  style,
  required,
  placeholder,
  picker = 'date',
  mode = DatePickerMode.SINGLE, // 'single' or 'range'
  startDate,
  endDate,
  tomorrow,
  disabled,
  infoTitle,
  dateFormat = defaultDateFormat,
  ...props
}: InputProps & {
  mode?: typeof DatePickerMode.SINGLE | typeof DatePickerMode.RANGE;
  tomorrow?: boolean;
  dateFormat?: string;
}) => {
  const { t } = useTranslation();

  if (!name) {
    throw new Error('InputField requires a "name" prop.');
  }

  const [field, meta, helpers] = useField<string>(name);
  const error = t(controlError(meta, name, t(placeholder ?? '') as string) as string);

  const parseFieldDate = () => {
    try {
      return JSON.parse(field.value);
    } catch (error) {
      return null;
    }
  };

  const selectedRangeDate: Dayjs | [Dayjs, Dayjs] | null = useMemo(() => {
    if (mode === 'range') {
      if (field.value) {
        return [
          dayjs(parseFieldDate()?.[0] ?? startDate, dateFormatList[0]),
          dayjs(parseFieldDate()?.[1] ?? endDate, dateFormatList[0]),
        ];
      }

      if (placeholder) {
        return [dayjs(placeholder, dateFormatList[0]), dayjs(placeholder, dateFormatList[0])];
      }
    }

    return null;
  }, [field.value, placeholder, mode]);

  const selectedDate: Dayjs | [Dayjs, Dayjs] | null = useMemo(() => {
    if (field.value) {
      return dayjs(field.value, dateFormat);
    }

    if (placeholder) {
      return dayjs(placeholder, dateFormat);
    }

    return null;
  }, [field.value, placeholder]);

  const disabledDate = (current: Dayjs) => {
    if (tomorrow) {
      const tomorrow = dayjs().add(1, 'day');
      return current.isBefore(tomorrow, 'day');
    }
    return false;
  };

  return (
    <div className={s['container']}>
      <div className={s['wrapper-label']}>
        {info && (
          <CustomTooltip title={infoTitle ?? ''} widthInner="550">
            <div className={s['info']}>
              <InfoIcon width={16} height={16} />
            </div>
          </CustomTooltip>
        )}
        <span className={s['label']}>{t(label ?? '')}</span>
        {required && <RequiredIcon />}
      </div>
      {mode === 'range' ? (
        <DatePicker.RangePicker
          value={selectedRangeDate}
          allowClear={false}
          separator={<>-</>}
          format={dateFormatList}
          className={cx(s['input'], s['inputRange'])}
          style={style}
          locale={locale}
          onChange={(dates, dateStrings) => {
            helpers.setValue(JSON.stringify(dateStrings));
          }}
          disabled={disabled}
          disabledDate={disabledDate}
        />
      ) : (
        <DatePicker
          {...field}
          value={selectedDate as Dayjs | null | undefined}
          format={dateFormat}
          className={s['input']}
          locale={locale}
          style={style}
          picker={picker as any}
          onChange={(date, dateString) => {
            helpers.setValue(dateString);
          }}
          disabled={disabled}
          disabledDate={disabledDate}
        />
      )}

      {error && <ErrorLabel error={error} />}
    </div>
  );
};
