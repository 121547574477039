import cx from 'classnames';
import { Route, Routes, useParams } from 'react-router-dom';

import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';
import { ButtonProps } from 'components/ui/Button';
import { Documents } from './documents/documents';
import { RequisitesDetail } from './requisites/RequisitesDetail';

import { AppRoutes } from 'config/routes';

import { Loader } from 'components/loader/loader';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useGetPartnerOrganizationQuery } from 'store/api/organizations';
import s from './CounterpatyDetail.module.scss';
import { DefaultDateFormat } from 'utils/date';

const { Home, Counterparties } = AppRoutes.Dashboard;

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'Requisites',
    path:
      Home +
      Counterparties.Home +
      Counterparties.CounterpatyDetail.Home +
      '/:id' +
      Counterparties.CounterpatyDetail.Requisites,
  },
  {
    id: 1,
    label: 'Documents',
    path:
      Home +
      Counterparties.Home +
      Counterparties.CounterpatyDetail.Home +
      '/:id' +
      Counterparties.CounterpatyDetail.Documents,
  },
];

export function createButtonSettings(settings: Omit<ButtonProps, 'children'>): ButtonProps {
  return settings;
}

export const CounterpatyDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: partner, isFetching } = useGetPartnerOrganizationQuery(
    { id: id ?? '' },
    { skip: !id },
  );

  const counterparty = partner?.organization;

  const classesButton = cx(s.button);

  const buttonSettings = createButtonSettings({
    text: 'Block',
    type: 'primary',
    disabled: false,
    className: classesButton,
  });

  if (isFetching) {
    return <Loader size="ultra" />;
  }

  if (!counterparty) {
    return <div>Not found counterparty</div>;
  }

  return (
    <div className={s.container}>
      <div className={s.wrapperTop}>
        <span>{t('Counterparties')}</span>
        <div className={s.title}>
          <span className={s.name}>{counterparty?.name}</span>
          <span className={s.date}>{`${t('Counterparty')} c ${dayjs(
            partner.partnership_agreement_date,
          ).format(DefaultDateFormat)}`}</span>
        </div>
      
      </div>
      <div className={s.content}>
        <div>
          <HeaderTabs
            optionButton={buttonSettings}
            type="detail"
            tabs={tabsContent?.map((item) => ({
              ...item,
              path: item.path.replace(':id', id ?? ''),
            }))}
          />
        </div>

        <Routes>
          <Route path={Counterparties.CounterpatyDetail.Documents} element={<Documents />} />
          <Route
            path={Counterparties.CounterpatyDetail.Requisites}
            element={
              <RequisitesDetail payment={partner.default_payment_option} data={counterparty} />
            }
          />
        </Routes>
      </div>
    </div>
  );
};
