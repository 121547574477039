import { AppRoutes } from 'config/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';

import {
  useGetShipmentReportTemplateQuery,
  useCreateShipmentReportsMutation,
} from 'store/api/documents';
import { useHandleMutation } from 'utils/handleMutation';

import { ShipmentTemplateReport } from 'models';

export const useNew = () => {
  const navigate = useNavigate();

  const { Home } = AppRoutes.Dashboard;

  const [contractId, setContractId] = useState<string | undefined>();

  const [createShipmentReports, createShipmentReportsMutation] =
    useCreateShipmentReportsMutation();

  const { data: shipmentReportDocumentScheme } = useGetShipmentReportTemplateQuery({});

  const dropDownTemplateOptions = useMemo(() => {
    if (!shipmentReportDocumentScheme) return [];

    return shipmentReportDocumentScheme.map((item: ShipmentTemplateReport) => {
      const { name, required, label, options } = item;

      const dropdownOptions =
        options?.map((opt) => ({
          label: opt.label,
          value: opt.value,
        })) || [];

      return {
        name,
        required,
        label: label?.en,
        options: dropdownOptions,
      };
    });
  }, [shipmentReportDocumentScheme]);

  const handleCreateRegistry = useCallback(() => {
    navigate(Home + AppRoutes.Dashboard.ShipmentReports.Home);
  }, [navigate]);

  const onSuccessCreate = useCallback(async (id: string) => {
    setContractId(id);
    handleCreateRegistry();
  }, []);

  useHandleMutation({
    ...createShipmentReportsMutation,
    onSuccess: onSuccessCreate,
  });

  return {
    createShipmentReports,
    dropDownTemplateOptions,
    isLoading: createShipmentReportsMutation?.isLoading,
  };
};
