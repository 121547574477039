import { gql } from 'graphql-request';

export const CREATE_SHIPMENT_REPORTS_DOCUMENT = gql`
  mutation App5182dd71_Document($input: app5182dd71_ShipmentReport_Create_Input!) {
    app5182dd71_ShipmentReport {
      create(input: $input) {
        status
        message {
          ru
          en
        }
      }
    }
  }
`;
