import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { User } from 'components/sidenav/navbar/navbar';
import { DemoModeMark } from 'components/demo-mode-mark';
import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';

import s from './Navbar.module.scss';

export const Navbar = () => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <div style={{ maxWidth: '393px', flex: 1, paddingBlock: '16px' }}>
        {/* <Input
          placeholder={t('Search...')}
          prefix={<SearchIcon />}
          style={{ borderRadius: '0px', height: '40px' }}
        /> */}
        <DemoModeMark />
      </div>
      <User />
    </div>
  );
};
