import { Route, Routes } from 'react-router-dom';

import All from './all/All';
import { Details } from './details/Details';

import { AppRoutes } from 'config/routes';

import { NewRequestPage } from './update-task';
import { PreviewPage } from './preview/NewContractPreview';

function ShipmentRegistry() {
  const { NewRequest, RequestApproval, Preview } = AppRoutes.Dashboard.ShipmentRegistry;

  return (
    <Routes>
      <Route path="/" element={<All />} />
      <Route path="/:id/*" element={<Details />} />

      <Route path={NewRequest} element={<NewRequestPage />} />
      
      <Route path={RequestApproval + '/:id'} element={<NewRequestPage />} />

      <Route path={NewRequest + '/:id'} element={<NewRequestPage />} />

      <Route path={Preview + '/:id'} element={<PreviewPage />} />
    </Routes>
  );
}

export default ShipmentRegistry;
