import * as x509 from '@peculiar/x509';

// Define OIDs
const oids = {
  State: '2.5.4.5',
  Street: '2.5.4.9',
  CommonName: '2.5.4.3',
  Country: '2.5.4.6',
  GivenName: '2.5.4.42',
  Locality: '2.5.4.7',
  Organization: '2.5.4.10',
  OrganizationalUnit: '2.5.4.11',
  Position: '2.5.4.12',
  PostalCode: '2.5.4.17',
  Province: '2.5.4.8', // state
  SerialNumber: '2.5.4.5',
  StreetAddress: '2.5.4.9',
  Surname: '2.5.4.4',
  Email: '1.2.840.113549.1.9.1',
  INN: '1.2.643.3.131.1.1',
  INNLegalEntity: '1.2.643.100.4',
  OGRN: '1.2.643.100.1',
  OGRNIP: '1.2.643.100.5',
  SNILS: '1.2.643.100.3',
};

class PKI {
  constructor() {
    // Set the browser's native crypto as the provider for @peculiar/x509
    x509.cryptoProvider.set(window.crypto);
  }

  // Custom UUID v4 generator
  generateUUID() {
    // Generate 16 random bytes
    const bytes = new Uint8Array(16);
    window.crypto.getRandomValues(bytes);

    // Per RFC 4122, set bits for version and variant
    bytes[6] = (bytes[6] & 0x0f) | 0x40; // Version 4
    bytes[8] = (bytes[8] & 0x3f) | 0x80; // Variant 10

    // Convert bytes to hexadecimal representation
    const byteToHex = [];
    for (let i = 0; i < 256; ++i) {
      byteToHex.push((i + 0x100).toString(16).substr(1));
    }

    const hex = Array.from(bytes, (byte) => byteToHex[byte]).join('');

    // Format as UUID string
    return `${hex.substr(0, 8)}-${hex.substr(8, 4)}-${hex.substr(12, 4)}-${hex.substr(
      16,
      4,
    )}-${hex.substr(20, 12)}`;
  }

  // Parse Attributes for Distinguished Name
  parseAttributes(attrs = {}) {
    const exts = {};
    const reqAttrs = [];

    for (let [name, value] of Object.entries(attrs)) {
      let attrValue;
      if (typeof value === 'string') {
        attrValue = value;
      } else if (value.utf8) {
        attrValue = value.utf8;
      } else {
        throw new Error('Failed to parse x509 attribute type: unsupported');
      }

      const oid = oids[name];
      if (!oid) {
        throw new Error(`Unsupported attribute name: ${name}`);
      }

      reqAttrs.push({ [name]: [attrValue] });
      exts[name] = oid;
    }

    return new x509.Name(reqAttrs, exts);
  }

  // Generate Certificate Authority (CA) Certificate
  async generateCA(opts = {}, attrs = {}) {
    const alg = {
      name: 'ECDSA',
      namedCurve: 'P-256',
      hash: 'SHA-256',
    };

    // Generate ECDSA P-256 key pair using the browser's Web Crypto API
    const keys = await window.crypto.subtle.generateKey(alg, false, ['sign', 'verify']);

    // Generate a unique serial number
    const serialNumber = opts.serialNumber || '01' + this.generateUUID().replace(/-/g, '');

    // Define certificate options
    const certOptions = {
      serialNumber: serialNumber,
      name: this.parseAttributes(attrs),
      notBefore: new Date(),
      notAfter: new Date(Date.now() + 1000 * 60 * 60 * 24 * 31), // 31 days validity
      signingAlgorithm: alg,
      keys: keys,
      extensions: [
        new x509.BasicConstraintsExtension(true, 1, true), // cA=true, pathLen=1, critical=true
        new x509.ExtendedKeyUsageExtension(['1.2.3.4.5.6.7', '2.3.4.5.6.7.8'], true),
        new x509.KeyUsagesExtension(
          x509.KeyUsageFlags.keyCertSign | x509.KeyUsageFlags.cRLSign,
          true,
        ),
        await x509.SubjectKeyIdentifierExtension.create(keys.publicKey),
      ],
    };

    try {
      // Create a self-signed certificate
      const cert = await x509.X509CertificateGenerator.createSelfSigned(certOptions);

      return {
        publicKey: keys.publicKey,
        privateKey: keys.privateKey,
        cert,
        certPem: cert.toString(),
      };
    } catch (error) {
      throw new Error(`Error generating CA: ${error.message}`);
    }
  }
}

export const generateUser8DigitUUID = () => {
  const uuid = Math.floor(10000000 + Math.random() * 90000000).toString();
  return uuid.slice(0, 8);
};

export const generateRandomCertificate = async () => {
  const pki = new PKI();

  try {
    const generateInnLegalEntity = () => {
      const inn = Math.floor(100000000000 + Math.random() * 900000000000).toString();
      return inn.slice(0, 12);
    };

    const generateSnils = () => {
      const snils = Math.floor(10000000000 + Math.random() * 90000000000).toString();
      return snils.slice(0, 11);
    };

    const generateOgrn = () => {
      const ogrn = Math.floor(1000000000000 + Math.random() * 9000000000000).toString();
      return ogrn.slice(0, 13);
    };

    const INNLegalEntity = generateInnLegalEntity();
    const SNILS = generateSnils();
    const OGRN = generateOgrn();

    const ca = await pki.generateCA(
      {},
      {
        INNLegalEntity,
        SNILS,
        OGRN,
        // INN: '',
        Country: 'RU',
        Province: { utf8: 'MOSCOW' },
        Locality: { utf8: 'MOSCOW' },
        Street: { utf8: 'MOSCOW' },
        Organization: { utf8: 'Organization' },
        CommonName: { utf8: 'Organization' },
        Position: { utf8: 'Head' },
        GivenName: { utf8: 'Ivan Ivan' },
        Surname: { utf8: 'Ivanov' },
        // OGRNIP: '2222222222222',
      },
    );

    const purePem = ca.certPem
      .replace(/-----BEGIN CERTIFICATE-----/, '')
      .replace(/-----END CERTIFICATE-----/, '')
      .replace(/\n/g, '');

    console.log('Pure PEM:\n', purePem);

    const derBuffer = ca.cert.rawData; // Assuming 'rawData' contains the DER bytes as Uint8Array
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', derBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    const Thumbprint = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase(); // Convert bytes to hex string

    return { purePem, Thumbprint, INNLegalEntity, SNILS, OGRN, UUID: generateUser8DigitUUID() };
  } catch (error) {
    console.error(error.message);
  }
};
