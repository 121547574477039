import { useState } from 'react';
import cx from 'classnames';
import { Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { useNew } from '../update-task/use-new-task';

import { Button } from 'components/ui/Button';
import { DatePickerField } from 'components/common';
import { SelectField } from 'components/fields/select-fields/select-filed';

import { model, validationSchema, initialValues } from './config';

import s from './New.module.scss';

function New() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { createShipmentReports, dropDownTemplateOptions } = useNew();

  const [selectedAgreement, setSelectedAgreement] = useState(undefined);

  const classesButtonCreateNewRegister = cx(s['button'], s['button-create-new-register']);
  const classesButtonCancelCreateNewRegister = cx(
    s['button'],
    s['button-cancel-create-new-register'],
  );

  const onBack = () => {
    navigate(AppRoutes?.Dashboard?.ShipmentReports?.Home);
  };

  const selectField = dropDownTemplateOptions.find(
    (option: { name: string }) => option.name === 'agreement',
  );

  const onSubmit = async (values: FormikValues) => {
    const [month, year] = values.shipmentRegistryPeriod.split('.');

    const monthIndex = parseInt(month, 10) - 1;
    const yearNumber = parseInt(year, 10);

    await createShipmentReports({
      input: {
        documentId: values.agreement,
        monthIndex,
        year: yearNumber,
      },
    });
  };

  return (
    <>
      <span className={s['title']}>{t('Новый отчет по отгрузкам')}</span>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
      >
        {({ dirty, errors, submitForm, isSubmitting, resetForm }) => (
          <div className={s['container-content']}>
            <div className={s['top-inner-content']}>
              <SelectField
                {...model.agreement}
                options={selectField?.options ?? []}
                onChange={(item: any) => {
                  setSelectedAgreement(item);
                }}
              />

              <DatePickerField {...model.period} />
            </div>

            <div className={s['bottom-outer-content']}>
              <Button
                displayType="outlined-tetriary"
                className={classesButtonCancelCreateNewRegister}
                text={t('Cancel')}
                onClick={() => {
                  onBack();
                  resetForm();
                }}
              />
              <Button
                type="primary"
                disabled={!dirty}
                className={classesButtonCreateNewRegister}
                text={t('ShipmentReports.createReports')}
                onClick={() => {
                  submitForm();
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default New;
