import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMockClientStatus } from 'utils/mock-client';

export const DemoModeMark = ({style}: any) => {
  const { t } = useTranslation();

  const [isDemoMode, setIsDemoMode] = useState(false);

  useEffect(() => {
    const isDemoMode = getMockClientStatus();
    setIsDemoMode(!!isDemoMode);
  }, []);

  if (!isDemoMode) {
    return null;
  }

  return (
    <div
      style={{
        borderStyle: 'dashed',
        borderColor: '#ff4500',
        width: 500,
        borderWidth: 1,
        padding: 10,
        color: '#ff4500',
        ...style,
      }}
    >
      {t('Demo Mode Mark')}
    </div>
  );
};
