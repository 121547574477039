import { useTranslation } from 'react-i18next';

import { ProgressLine } from 'components/common/progpress/progress';
import { Status } from 'components/status/status';

import dayjs from 'dayjs';
import { AppDocument } from 'store/api/types/documents';
import { DefaultDateFormat } from 'utils/date';
import s from './BasicInformation.module.scss';

interface Term {
  id: number;
  title: string;
  date: string;
}

interface BasicInformationTerm {
  id: number;
  term: Term[];
  discount: string;
  totalAmount: string;
}

export const dataBasicInformationTerm = [
  {
    id: 0,
    term: [
      {
        id: 0,
        title: 'Payment term',
        date: '05.08.2023',
      },
      {
        id: 1,
        title: 'Delivery time',
        date: '08.08.2023',
      },
      {
        id: 2,
        title: 'Period of validity of the contract',
        date: '04.08.2023 - 15.08.2023',
      },
    ],
    discount: '25',
    totalAmount: '4000',
  },
];

export const CalculationProcedure = ({
  title,
  dataTerm,
}: {
  title: string;
  dataTerm: Term[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={s['wrapper-procedure']}>
      <div className={s['wrapper-title']}>
        <span className={s['title']}>{t(title)}</span>
        <div>
          <Status text="Procedure" variant="active" />
        </div>
      </div>
      <div className={s['wrapper-term']}>
        {dataTerm?.map((item) => {
          if (!item) {
            return null;
          }
          return (
            <div key={item.id.toString()} className={s['description']}>
              <span className={s['title-term']}>{t(item.title)}</span>
              <span className={s['date']}>{item.date}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Basic = ({ document }: { document: AppDocument }) => {
  const { t } = useTranslation();

  const hasPrePayment = document?.template?.id === '7e27cd52-1c33-4d67-8adf-8d8b5230acaa';

  const documentSubject = document?.parameters?.find(
    (item) => item.name === 'DocumentSubject',
  )?.value;
  const documentDraftingPlace = document?.parameters?.find(
    (item) => item.name === 'DraftingPlace',
  )?.value;

  return (
    <div className={s['wrapper-procedure']}>
      {false && (
        <div className={s['wrapper-title']}>
          <span className={s['title']}>{t('Basic parameters')}</span>
        </div>
      )}
      <div className={s['wrapper-term']}>
        {false && (
          <div className={s['description']}>
            <span className={s['title-term']}>{t('Document version')}</span>
            <span className={s['date']}>{document.version}</span>
          </div>
        )}

        <div className={s['description']}>
          <span className={s['title-term']}>{t('Counterparty')}</span>
          <span className={s['date']}>{document?.orgOwner?.name}</span>
        </div>

        <div className={s['description']}>
          <span className={s['title-term']}>{t('Date of conclusion')}</span>
          <span className={s['date']}>
            {dayjs(document?.docDate).format(DefaultDateFormat)}
          </span>
        </div>

        <div className={s['description']}>
          <span className={s['title-term']}>{t('Expiration date')}</span>
          <span className={s['date']}>
            {document?.validTo ? dayjs(document?.validTo).format(DefaultDateFormat) : '-'}
          </span>
        </div>

        <div className={s['description']}>
          <span className={s['title-term']}>{t('Place of conclusion of the contract')}</span>
          <span className={s['date']}>{documentDraftingPlace}</span>
        </div>

        {hasPrePayment && (
          <div className={s['description']}>
            <span className={s['title-term']}>{t('Payment type')}</span>
            <span className={s['date']}>Предоплата 100%</span>
          </div>
        )}

        {documentSubject && (
          <div className={s['description']}>
            <span className={s['title-term']}>{t('Document subject')}</span>
            <span className={s['date']}>{documentSubject}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const TotalAmount = ({ data }: { data: BasicInformationTerm }) => {
  const { t } = useTranslation();

  return (
    <div className={s['container-total']}>
      <div className={s['wrapper-discount']}>
        <span className={s['title-discount']}>{t('The size of the partner discount')}</span>
        <div className={s['content-discount']}>
          <span className={s['discount']}>{data.discount}%</span>
          <ProgressLine percent={25} />
          <span className={s['description-discount']}>
            {t(`750.000 ₽ ${t('it remains until')} 35% ${t('discounts')}`)}
          </span>
        </div>
      </div>
      <div className={s['line']}></div>
      <div className={s['wrapper-total']}>
        <span className={s['title-total']}>{t('Total amount')}</span>
        <span className={s['total']}>{data.totalAmount} ₽</span>
      </div>
    </div>
  );
};

export const BasicInformation = ({ document }: { document: AppDocument }) => {
  return (
    <div className={s['container']}>
      <Basic document={document} />
      {false && <TotalAmount data={dataBasicInformationTerm[0]} />}
    </div>
  );
};
