import { applyTheme as applyThemeNumber } from 'modules/dashboard/theme/helpers';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentCertificateInfo } from 'store/reducers/certificates';
import { getEnvMockValue } from 'utils/mock-client';

export const useRestoreTheme = () => {
  const defaultTheme = 'theme-1';

  useLayoutEffect(() => {
    const applyTheme = (themeName: string) => {
      document.documentElement.setAttribute('data-theme', themeName);
    };

    const savedTheme = localStorage.getItem('selectedTheme');
    const initialTheme = savedTheme || defaultTheme;
    applyTheme(initialTheme);
  }, []);
};

export const useMockTheme = () => {
  const userInfo = useSelector(selectCurrentCertificateInfo());

  if (getEnvMockValue()) {
    if (userInfo?.['Компания']?.includes('Поставщик')) {
      applyThemeNumber(1);
    }
    if (userInfo?.['Компания']?.includes('Покупатель')) {
      applyThemeNumber(3);
    }
    if (userInfo?.['Компания']?.includes('Подрядная организация')) {
      applyThemeNumber(4);
    }
  }
};
