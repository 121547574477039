import { TFunction } from "i18next";
import * as Yup from 'yup'
import dayjs from 'dayjs'

import { DatePickerMode, DateFormatList } from 'components/common/date-picker/date-picker';

export interface ShipmentRegistryNew {
  agreement: string;
  period: string;
}

export const model = {
  agreement: {
    label: 'agreementName',
    name: 'agreement',
    placeholder: 'ShipmentRegistry.selectAgreement',
    options: ['Agreement 1', 'Aggrement 2', 'Agreement 3'].map((label) => ({
      label,
      value: label
    })),
    required: true,
  },
  period: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'shipmentRegistryPeriod',
    label: 'ShipmentRegistry.period',
    // tomorrow: true,
    dateFormat: DateFormatList?.MM_YYYY,
    picker: "month",
  }
}

export const validationSchema = (t: TFunction) => Yup.object().shape({
  [model.agreement.name]: Yup.string()
    .required(t('Please select from the list, this field is required')),
    [model.period.name]: Yup.date()
    .transform((value, originalValue) => {
      const parsedDate = dayjs(originalValue, 'MM.YYYY', true);
      return parsedDate.isValid() ? parsedDate.toDate() : new Date('');
    })
    .typeError(t('Value must be a date type, but the final value was: Invalid Date'))
    .required(t('This field is required'))
});

export const initialValues = {
  [model.agreement.name]: '',
  [model.period.name]: '',
}