import { useTranslation } from 'react-i18next';

import Accordion from 'components/accordion/accordion';
import AccordionItem from 'components/accordion/accordion-item/accordion-item';

import s from './Authority.module.scss';
import { StaffPermission } from 'modules/dashboard/staff/staff-detail/staff-permission';

// const items = [
//   {
//     key: 'panel1',
//     title: 'Просмотр данных об организации',
//     content: 'Доверенность.xml',
//     status: 'The power of attorney has expired',
//     variant: 'secondary-expectation',
//   },
//   {
//     key: 'panel2',
//     title: 'Panel 2',
//     content: 'Content of Panel 2',
//   },
//   {
//     key: 'panel3',
//     title: 'Panel 3',
//     content: 'Content of Panel 3',
//     status: 'Authority revoked',
//     variant: 'expectation',
//   },
//   {
//     key: 'panel4',
//     title: 'Disabled Panel',
//     content: 'This panel is disabled',
//     isDisabled: true,
//   },
// ];

export const Authority = () => {
  const { t } = useTranslation();

  const mockedPermissions = [
    'Приглашение контрагентов',
    'Рассмотрение приглашений от контрагентов',
    'Подписание соглашения о порядке обработки данных',
    'Подписание договорных документов организации',
    'Управление профилем организации',
    'Управление сотрудниками и их полномочиями',
  ];

  return (
    <div className={s['container']}>
      <div className={s['header']}>
        <div className={s['wrapper-attributes']}>
          <span className={s['title']}>{t('Role')}</span>
          <span className={s['decs']}>{t('Администратор')}</span>
        </div>
        <div className={s['wrapper-attributes']}>
          <span className={s['title']}>{t('Access')}</span>
          <span className={s['decs']}>{t('Ко всем документам и разделам')}</span>
        </div>
      </div>

      <div className={s['wrapper-authority']}>
        <span className={s['title']}>{t('Authority')}</span>

        {mockedPermissions.map((permission) => (
          <StaffPermission key={permission} title={permission} />
        ))}

        {/* <Accordion
          accordion={false}
          bordered={true}
          collapsible="header"
          defaultActiveKey={['panel1']}
          expandIconPosition="end"
        >
          {items.map((item) => (
            <AccordionItem key={item.key} item={item} />
          ))}
        </Accordion> */}
      </div>
    </div>
  );
};
