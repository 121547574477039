import { SideLinks } from 'components/sidelinks/sidelinks';
import { Link } from 'react-router-dom';


import { ContracteumLogo } from 'components/contracteum-logo';
import { linksGeneral, linksLimitedUser } from 'config/links';
import { useLimits } from 'hooks';
import styles from './Sidenav.module.scss';

export const SideNav = () => {
  const { isFetching, isLimitedUser } = useLimits();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperLogo}>
          <Link style={{ textDecoration: 'none' }} to="/" className={styles.logo}>
            <ContracteumLogo />
          </Link>
        </div>

        <div className={styles.content}>
          <div className={styles.links}>
            <SideLinks links={isLimitedUser ? linksLimitedUser : linksGeneral} />
            {!isFetching && isLimitedUser && (
              <div className={styles.limitedUser}>
                <span>
                  {
                    'Вы не связаны ни с одной организацией. Вы можете пользоваться только разделом `Доверенности`.'
                  }
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
