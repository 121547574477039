import { useQuery } from '@tanstack/react-query';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCertificate, setCurrentCertClass } from 'store/reducers/certificates';
import { setCertClass } from 'utils/certClass';
import { certClass } from '../api';

export default function useCertClass() {
  const dispatch = useDispatch();
  const currentCertificate = useSelector(selectCurrentCertificate());

  const { data, refetch } = useQuery({
    queryFn: () => certClass(currentCertificate?.Base64RawData ?? ''),
  });

  useEffect(() => {
    if (currentCertificate?.Base64RawData) {
      refetch();
    }
  }, [currentCertificate?.Base64RawData]);

  useEffect(() => {
    if (data?.cert_class) {
      dispatch(setCurrentCertClass(data?.cert_class));
      setCertClass(data?.cert_class);
    }
  }, [data]);
}
