import { useTranslation } from 'react-i18next';

import { useMeQuery } from 'store/api/profile';
import { Profile, ProfileAttribute } from 'store/api/types';
import s from './PersonalData.module.scss';
import { useMemo } from 'react';
import { useGetCoreDictionariesCountriesQuery } from 'store/api/dictionaries';

export const LeftInfo = ({ attributes }: { attributes?: ProfileAttribute[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s['left-container']}>
      {attributes
        ?.filter((item) => item.value)
        ?.map((item) => (
          <div className={s['attribute-item']} key={item?.name}>
            <span style={{ fontWeight: '600' }}>{t(item.name)}: </span>
            <span>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

export const RightInfo = ({ attributes }: { attributes?: ProfileAttribute[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s['right-content']}>
      {attributes
        ?.filter((item) => item.value)
        ?.map((item) => (
          <div className={s['attribute-item']} key={item?.name}>
            <span style={{ fontWeight: '600' }}>{t(item.name)}: </span>
            <span>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

const leftInfoWhitelist = ['lastName', 'firstName', 'middleName'];
const leftInfoAttrWhitelist = ['kpp', 'snils', 'inn', 'inn_legal_entity', 'country'];
const rightInfoWhitelist = ['phone', 'email'];

export const PersonalData = () => {
  const { data: userInfo, isFetching } = useMeQuery();
  const { data: [countriesDictionary] = [] } = useGetCoreDictionariesCountriesQuery();

  const transformCountryCode = (code: string) => {
    const country = countriesDictionary?.enums?.find(
      (item) => item?.title?.toLowerCase().trim() === code?.trim()?.toLowerCase(),
    )?.values;

    if (code?.trim()?.toLocaleLowerCase() === 'ru') {
      return 'Россия';
    }

    if (code?.trim()?.toLocaleLowerCase() === 'kg') {
      return 'Киргизия';
    }

    const countryFullName =
      country?.find((item) => item?.key === 'fullname')?.value ??
      country?.find((item) => item?.key === 'shortname')?.value ??
      code;

    return countryFullName;
  };

  const leftInfoData = useMemo(() => {
    if (!userInfo || !userInfo.attributes) return [];

    return [
      ...leftInfoWhitelist.reduce<ProfileAttribute[]>((acc, fieldName) => {
        const value = userInfo[fieldName as keyof Profile];
        if (typeof value === 'string' && value) {
          acc.push({ name: fieldName, value });
        }
        return acc;
      }, []),

      ...(
        userInfo.attributes?.filter((attr) => leftInfoAttrWhitelist.includes(attr.name)) || []
      ).map((item) => {
        if (item.name === 'country') {
          return {
            name: item.name,
            value: transformCountryCode(item.value),
          };
        }
        return item;
      }),
    ];
  }, [userInfo]);

  const rightInfoData = useMemo(() => {
    if (!userInfo || !userInfo?.attributes) return [];

    return [
      ...rightInfoWhitelist.reduce<ProfileAttribute[]>((acc, fieldName) => {
        const value = userInfo[fieldName as keyof Profile];
        if (typeof value === 'string' && value) {
          acc.push({ name: fieldName, value });
        }
        return acc;
      }, []),

      ...(userInfo.attributes?.filter((attr) => rightInfoWhitelist.includes(attr.name)) || []),
    ];
  }, [userInfo]);

  if (isFetching) {
    return null;
  }

  return (
    <div className={s['container']}>
      <div className={s['wrapper-content']}>
        <LeftInfo attributes={leftInfoData} />
        <RightInfo attributes={rightInfoData} />
      </div>
    </div>
  );
};
